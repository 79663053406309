<template>
  <div class="panel-container">
    <div
      class="panel"
      :style="{
        width: this.width + '%',
      }"
    >
      <h2 v-if="this.header" class="panel__header">{{ this.header }}</h2>
      <slot name="panelContent" />
    </div>
  </div>
</template>

<script lang="ts">
import "./panel.scss";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "Panel",
  components: {},
})
export default class Panel extends Vue {
  //#region Component
  @Prop() header: string;
  @Prop({ default: 100 }) width: number;
  //#endregion
}
</script>
