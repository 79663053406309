<template>
  <div
    v-show="this.splashImageUrl"
    :class="{
      page__splash: this.hasSplash,
    }"
    :style="{
      'background-image': 'url(' + this.splashImageUrl + ')',
    }"
  >
    <div v-show="this.splashText" class="page__splash-text__container">
      <div class="page__splash-text__container--content">
        {{ this.splashText }}
      </div>
    </div>

    <div v-show="this.dealsRichText" class="page__deals-text__container">
      <div class="page__deals-text__container--content">
        <RichTextRenderer :document="this.dealsRichText" />
      </div>
      <div class="page__deals-text__container--buttons">
        <button class="footer__button" @click="onClickOurDeals">
          Our Deals
        </button>
        <button class="footer__button" @click="onClickRequestAQuote">
          Request a Quote
        </button>
      </div>
    </div>
  </div>

  <div v-show="this.hasSplash && this.splashImageUrl" class="page__caret-down">
    <font-awesome-icon :icon="['fas', 'angle-down']" size="2x" />
  </div>

  <div class="page__content">
    <slot name="pageContent" />
  </div>
  <Footer />
</template>

<script lang="ts">
import "./page.scss";
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Footer from "@/components/footer/footer.vue";
import { ContentfulEntityRichText } from "@/store/contentful.types";
import RichTextRenderer from "contentful-rich-text-vue-renderer";

@Options({
  name: "Page",
  components: {
    Footer,
    RichTextRenderer,
  },
})
export default class Page extends Vue {
  //#region Component
  @Prop({ default: true }) hasSplash: boolean;
  @Prop() splashImageUrl: string | null;
  @Prop() splashText: string;
  @Prop() dealsRichText: ContentfulEntityRichText;

  onClickOurDeals(): void {
    router.push({ path: "/our-deals" });
  }

  onClickRequestAQuote(): void {
    router.push({ path: "/quote" });
  }
  //#endregion
}
</script>
