
import "./page.scss";
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Footer from "@/components/footer/footer.vue";
import { ContentfulEntityRichText } from "@/store/contentful.types";
import RichTextRenderer from "contentful-rich-text-vue-renderer";

@Options({
  name: "Page",
  components: {
    Footer,
    RichTextRenderer,
  },
})
export default class Page extends Vue {
  //#region Component
  @Prop({ default: true }) hasSplash: boolean;
  @Prop() splashImageUrl: string | null;
  @Prop() splashText: string;
  @Prop() dealsRichText: ContentfulEntityRichText;

  onClickOurDeals(): void {
    router.push({ path: "/our-deals" });
  }

  onClickRequestAQuote(): void {
    router.push({ path: "/quote" });
  }
  //#endregion
}
