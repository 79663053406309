
import "./panel.scss";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Options({
  name: "Panel",
  components: {},
})
export default class Panel extends Vue {
  //#region Component
  @Prop() header: string;
  @Prop({ default: 100 }) width: number;
  //#endregion
}
