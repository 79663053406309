
import "./footer.scss";
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { getImageUrlFromContentfulEntity } from "@/store/utils";
import { FETCH_FOOTER } from "@/store/modules/footer/footer.actions";
import { FOOTER_ENTRY_ID } from "@/store/api.config";
import { FooterEntity } from "@/store/modules/footer/footer.model";
import { ContactDetailsEntity } from "@/store/modules/contact-details/contact-details.model";
import { NGrid, NGridItem, NDivider, NButton } from "naive-ui";
import Applink from "@/components/applink/applink.vue";

@Options({
  name: "Footer",
  components: {
    NGrid,
    NGridItem,
    NDivider,
    NButton,
    Applink,
  },
})
export default class Footer extends Vue {
  //#region Component
  isLoading = true;
  footerEntity: FooterEntity | null = null;
  contactDetailsEntity: ContactDetailsEntity | null = null;

  firstName: string | null = null;
  phoneNumber: string | null = null;
  phoneNumberExtended: string | null = null;
  email: string | null = null;
  address: string | null = null;
  businessHoursLines: Array<string> | null = null;
  facebookUrl: string | null = null;
  instagramUrl: string | null = null;
  linkedinUrl: string | null = null;

  showSiteMap = false;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_FOOTER, FOOTER_ENTRY_ID);
    this.footerEntity = this.$store.getters.footerEntity;
    this.contactDetailsEntity = this.$store.getters.contactDetailsEntity;
    this.firstName = this.contactDetailsEntity?.contactDetailsFirstNameText;
    this.phoneNumber =
      this.contactDetailsEntity?.contactDetailsPhoneFormattedText;
    this.phoneNumberExtended =
      this.contactDetailsEntity?.contactDetailsPhoneExtendedText;
    this.email = this.contactDetailsEntity?.contactDetailsEmailText;
    this.address = this.contactDetailsEntity?.contactDetailsAddressText;
    this.businessHoursLines =
      this.contactDetailsEntity?.contactDetailsBusinessHoursRichText.content[0].content[0].value.split(
        "\n"
      );
    this.facebookUrl = this.contactDetailsEntity?.contactDetailsFacebookUrl;
    this.instagramUrl = this.contactDetailsEntity?.contactDetailsInstagramUrl;
    this.linkedinUrl = this.contactDetailsEntity?.contactDetailsLinkedinUrl;
    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getFooterLogoImageUrl(index: number): string {
    return getImageUrlFromContentfulEntity(
      this.footerEntity.footerLogoImages[index]
    );
  }

  getFooterQualificationImageUrl(index: number): string {
    return getImageUrlFromContentfulEntity(
      this.footerEntity.footerQualificationImages[index]
    );
  }

  onClickContactUs(): void {
    router.push({ path: "/contact" });
  }

  onClickRequestAQuote(): void {
    router.push({ path: "/quote" });
  }
  //#endregion
}
