<template>
  <div v-if="!this.isLoading" class="footer">
    <n-grid cols="300:2 600:4 850:10" :x-gap="20">
      <n-grid-item span="0 300:2 600:1 850:2" />

      <n-grid-item span="2 600:2 850:2" class="footer__grid-item--header">
        <img class="footer__logo" src="/spartan-energy-logo-white.png" />
        <hr />
        <n-grid cols="3" :x-gap="4" :y-gap="4">
          <n-grid-item class="footer__grid-item--media-icon">
            <Applink
              :to="this.facebookUrl"
              activeClass="applink--active--white"
              inactiveClass="applink--inactive--white"
            >
              <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x" />
            </Applink>
          </n-grid-item>
          <n-grid-item class="footer__grid-item--media-icon">
            <Applink
              :to="this.instagramUrl"
              activeClass="applink--active--white"
              inactiveClass="applink--inactive--white"
            >
              <font-awesome-icon
                :icon="['fab', 'instagram-square']"
                size="2x"
              />
            </Applink>
          </n-grid-item>
          <n-grid-item class="footer__grid-item--media-icon">
            <Applink
              :to="this.linkedinUrl"
              activeClass="applink--active--white"
              inactiveClass="applink--inactive--white"
            >
              <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
            </Applink>
          </n-grid-item>
        </n-grid>
        <hr />
        <n-grid cols="2" :x-gap="10">
          <n-grid-item span="1">
            <button class="footer__button" @click="onClickContactUs">
              Contact Us
            </button>
          </n-grid-item>
          <n-grid-item span="1">
            <button class="footer__button" @click="onClickRequestAQuote">
              Request a Quote
            </button>
          </n-grid-item>
        </n-grid>
      </n-grid-item>

      <n-grid-item span="1 600:1 850:0" />
      <n-grid-item span="1 600:1 850:0" />

      <n-grid-item
        span="1 600:1 850:1"
        class="footer__grid-item--qualifications"
      >
        <div
          v-for="(item, index) in this.footerEntity.footerQualificationImages"
          :key="item.sys.id"
          class="qualification-image__container"
        >
          <img
            class="qualification-image"
            :src="this.getFooterQualificationImageUrl(index)"
          />
        </div>
      </n-grid-item>

      <n-grid-item span="1 600:1 850:1" class="footer__grid-item--logos">
        <div
          v-for="(item, index) in this.footerEntity.footerLogoImages"
          :key="item.sys.id"
          class="logo-image__container"
        >
          <img class="logo-image" :src="this.getFooterLogoImageUrl(index)" />
        </div>
      </n-grid-item>

      <n-grid-item span="1 600:1 850:0" />
      <n-grid-item span="1 600:1 850:0" />

      <n-grid-item v-if="this.showSiteMap" span="1" class="footer__grid-item">
        <h3>Site</h3>
        <Applink
          to="/home"
          activeClass="applink--active--white"
          inactiveClass="applink--inactive--white"
        >
          Home
        </Applink>
        <br />
        <Applink
          to="/about"
          activeClass="applink--active--white"
          inactiveClass="applink--inactive--white"
        >
          About Us
        </Applink>
        <br />
        <br />
        <Applink
          to="/solar"
          activeClass="applink--active--white"
          inactiveClass="applink--inactive--white"
        >
          Solar
        </Applink>
        <br />
        <Applink
          to="/batteries"
          activeClass="applink--active--white"
          inactiveClass="applink--inactive--white"
        >
          Batteries
        </Applink>
        <br />
        <br />
        <Applink
          to="/our-work"
          activeClass="applink--active--white"
          inactiveClass="applink--inactive--white"
        >
          Our Work
        </Applink>
        <br />
        <Applink
          to="/news"
          activeClass="applink--active--white"
          inactiveClass="applink--inactive--white"
        >
          News & Updates
        </Applink>
      </n-grid-item>
      <v-else n-grid-item span="1 600:1 850:0" />

      <n-grid-item span="1 600:1 850:0" />
      <n-grid-item span="1 600:1 850:0" />
      <n-grid-item span="1 600:1 850:0" />
      <n-grid-item span="1 600:1 850:0" />

      <n-grid-item span="2" class="footer__grid-item">
        <h3>Get in touch with Spartan Energy</h3>
        <Applink
          key="phone"
          :to="`tel:${this.phoneNumberExtended}`"
          activeClass="applink--active--white"
          inactiveClass="applink--inactive--white"
        >
          {{ this.firstName }} - {{ this.phoneNumber }}
        </Applink>
        <br />
        <Applink
          key="email"
          :to="`mailto:${this.email}`"
          activeClass="applink--active--white"
          inactiveClass="applink--inactive--white"
        >
          {{ this.email }}
        </Applink>
        <br />
        {{ this.address }}
        <h3 v-if="this.businessHoursLines">Business Hours</h3>
        <span v-for="(line, index) in this.businessHoursLines" :key="index">
          {{ line }}
          <br />
        </span>
      </n-grid-item>

      <n-grid-item span="0 600:0 850:2" />
    </n-grid>
    <div class="footer__extra-links">
      <Applink
        to="/terms-and-conditions"
        activeClass="applink--active--white"
        inactiveClass="applink--inactive--white"
      >
        <small>Terms and Conditions</small>
      </Applink>
      <Applink
        to="/privacy-policy"
        activeClass="applink--active--white"
        inactiveClass="applink--inactive--white"
      >
        <small>Privacy Policy</small>
      </Applink>
    </div>
    <div class="footer__copyright-text">
      {{ this.footerEntity.footerCopyrightText }}
    </div>
  </div>
</template>

<script lang="ts">
import "./footer.scss";
import router from "@/router";
import { Options, Vue } from "vue-class-component";
import { getImageUrlFromContentfulEntity } from "@/store/utils";
import { FETCH_FOOTER } from "@/store/modules/footer/footer.actions";
import { FOOTER_ENTRY_ID } from "@/store/api.config";
import { FooterEntity } from "@/store/modules/footer/footer.model";
import { ContactDetailsEntity } from "@/store/modules/contact-details/contact-details.model";
import { NGrid, NGridItem, NDivider, NButton } from "naive-ui";
import Applink from "@/components/applink/applink.vue";

@Options({
  name: "Footer",
  components: {
    NGrid,
    NGridItem,
    NDivider,
    NButton,
    Applink,
  },
})
export default class Footer extends Vue {
  //#region Component
  isLoading = true;
  footerEntity: FooterEntity | null = null;
  contactDetailsEntity: ContactDetailsEntity | null = null;

  firstName: string | null = null;
  phoneNumber: string | null = null;
  phoneNumberExtended: string | null = null;
  email: string | null = null;
  address: string | null = null;
  businessHoursLines: Array<string> | null = null;
  facebookUrl: string | null = null;
  instagramUrl: string | null = null;
  linkedinUrl: string | null = null;

  showSiteMap = false;
  //#endregion

  //#region Hooks
  created(): void {
    this.setup();
  }

  async setup(): Promise<void> {
    await this.$store.dispatch(FETCH_FOOTER, FOOTER_ENTRY_ID);
    this.footerEntity = this.$store.getters.footerEntity;
    this.contactDetailsEntity = this.$store.getters.contactDetailsEntity;
    this.firstName = this.contactDetailsEntity?.contactDetailsFirstNameText;
    this.phoneNumber =
      this.contactDetailsEntity?.contactDetailsPhoneFormattedText;
    this.phoneNumberExtended =
      this.contactDetailsEntity?.contactDetailsPhoneExtendedText;
    this.email = this.contactDetailsEntity?.contactDetailsEmailText;
    this.address = this.contactDetailsEntity?.contactDetailsAddressText;
    this.businessHoursLines =
      this.contactDetailsEntity?.contactDetailsBusinessHoursRichText.content[0].content[0].value.split(
        "\n"
      );
    this.facebookUrl = this.contactDetailsEntity?.contactDetailsFacebookUrl;
    this.instagramUrl = this.contactDetailsEntity?.contactDetailsInstagramUrl;
    this.linkedinUrl = this.contactDetailsEntity?.contactDetailsLinkedinUrl;
    this.isLoading = false;
  }
  //#endregion

  //#region Functions
  getFooterLogoImageUrl(index: number): string {
    return getImageUrlFromContentfulEntity(
      this.footerEntity.footerLogoImages[index]
    );
  }

  getFooterQualificationImageUrl(index: number): string {
    return getImageUrlFromContentfulEntity(
      this.footerEntity.footerQualificationImages[index]
    );
  }

  onClickContactUs(): void {
    router.push({ path: "/contact" });
  }

  onClickRequestAQuote(): void {
    router.push({ path: "/quote" });
  }
  //#endregion
}
</script>
